<template>
  <eagle-form
    :form-key="formKey"
  ></eagle-form>
</template>

<script lang="babel" type="text/babel">
import formMixin from '@/components/form/mixins/formMixin'
import formConfig from './otpFormConfig'
export default {
  mixins: [formMixin],
  data: () => ({
    formKey: 'otp-form',
    meta: {},
  }),
  methods: {
    async beforeFormInit() {
      await Promise.all([])
    },
    validateBeforeSave() {
      // TODO
    },
    async readApi(target) {
      console.warn('this.$api.collection.otpApi', this.$api.collection.otpApi)
      return await this.$api.collection.otpApi.read(target)
    },
    async createApi(formData) {
      return await this.$api.collection.otpApi.create(formData)
    },
    async updateApi(target, formData) {
      return await this.$api.collection.otpApi.update(target, formData)
    },
    async deleteApi(target) {
      return await this.$api.collection.otpApi.delete(target)
    },
    getFormConfig() {
      return formConfig
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
